/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardMedia, Box, Dialog, capitalize, CircularProgress } from '@mui/material';
import { CardContent, CardActions, Button } from '@mui/material';
// import { images as static_images } from '../Images.tsx';
// import SearchBar from 'material-ui-search-bar'; // You might need to install this component
import {addToCart} from './storeFunctions';
import CheckoutBar from './CheckoutBar.tsx';
import { getBackendPath } from '../utilities';

const StoreDesign = ({ title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [design, setDesign] = useState([]); 
  const [offeringsByCategory, setOfferingsByCategory] = useState({});
  const [loading, setLoading] = useState(true); // State for loading indicator

//   const [products, setProducts] = useState([]); 
//   const [open, setOpen] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);

//   const handleClickOpen = (image) => {
//     setSelectedImage(image);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedImage(null);
//   };

  useEffect(() => {
    const fetchProductCategories = async () => {
      const pathParts = location.pathname.split('/');
      const design = pathParts[pathParts.length - 2];
      
      try {
        const endpoint = getBackendPath(`/api/products/designs/${design}/`);
        const headers = {
          'Content-Type': 'application/json',
        };
        
        // Only include the Authorization header if a token is present
        const token = localStorage.getItem('token');
        if (token) {
          headers['Authorization'] = `Token ${token}`;
        }
        
        const response = await fetch(endpoint, {
          method: 'GET',
          headers,
        }
      );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        setDesign(data);
        // Process format_offerings to group them by category ID
        const formatOfferings = data.format_offerings;
        const newOfferingsByCategory = {};

        formatOfferings.forEach((offering) => {
          const categoryId = offering.category.id;
          if (!newOfferingsByCategory[categoryId]) {
            newOfferingsByCategory[categoryId] = {
              category: offering.category,
              offerings: [],
            };
          }
          newOfferingsByCategory[categoryId].offerings.push(offering);
        });

        // Now, sort the offerings within each category
        Object.values(newOfferingsByCategory).forEach((categoryObj) => {
          categoryObj.offerings.sort((a, b) => {
            // First, compare by price ascending
            const priceA = parseFloat(a.size.price);
            const priceB = parseFloat(b.size.price);
            if (priceA !== priceB) {
              return priceA - priceB;
            }
            // If prices are equal, compare by size name ascending
            const nameA = a.size.name.toUpperCase();
            const nameB = b.size.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names are equal
          });
        });
        setOfferingsByCategory(newOfferingsByCategory);
      } catch (error) {
        console.error('Error fetching product categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductCategories();
  }, []);

  if (loading) {
    // Show loading indicator while data is being fetched
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!design) {
    // Show error message if data failed to load
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography variant="h6">Failed to load design data.</Typography>
      </Box>
    );
  }
  
  return (
    <>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',  // Stack items vertically
        justifyContent: 'center', // Center vertically in the container
        alignItems: 'center'      // Center horizontally in the container
      }}>
        <Typography variant="h4" color="textSecondary" sx={{ mb: 2 }}>
          Store
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mx: 1, textAlign: 'center' }}>
          Next, pick a format for your design from the options below.
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ padding: '10px' }}>
        <Grid item xs={12} sm={6} md={4} >
            <Card sx={{ cursor: 'pointer' }}> {/* Add a pointer cursor to indicate clickability */}
            <CardMedia
              component="img"
              image={design.image.thumbnail_url}
              alt={design.name}
              sx={{
                height: 240,
                width: '100%',
                objectFit: 'contain',
              }}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {capitalize(design.name)}
                </Typography>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '8px', alignItems: 'center' }}>
                <Typography gutterBottom variant="body2" component="div">
                {design.description}
                </Typography>
                </div>
            </CardContent>
            </Card>
        </Grid>
        {/* Iterate over categories */}
        {design.unique_categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} key={category.id}>
            <Card>
              <CardMedia
                component="img"
                height="240"
                image={category.image_url}
                alt={category.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {category.description}
                </Typography>
                {/* List of format offerings */}
                {offeringsByCategory[category.id] &&
                  offeringsByCategory[category.id].offerings.map((offering) => (
                    <Box
                      key={offering.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <Typography variant="body2">
                        {offering.size.name} - ${offering.size.price}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => addToCart(design, offering)}
                      >
                        Add to Cart
                      </Button>
                    </Box>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
        


      </Grid>
      
      
    <CheckoutBar/>
    </>
  );
};

export default StoreDesign;
