import React from 'react';
import { Paper, BottomNavigation, Typography, BottomNavigationAction, Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const CheckoutBar = () => {
    //   const theme = useTheme();
    //   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [cartCount, setCartCount] = useState(0);
    const location = useLocation();

  // Check if the current path matches the pattern /store/{slug}
    const match = location.pathname.match(/^\/store\/[^/]+/);
    const linkTo = match ? match[0] : '/store';
    useEffect(() => {
        // Function to fetch the number of items in the cart from localStorage
        const updateCartCount = () => {
          const cart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
          const itemCount = cart.reduce((total, item) => total + item.quantity, 0);
          setCartCount(itemCount);
        };
      
        // Update cart count on component mount
        updateCartCount();
      
        // // Add an event listener for localStorage changes
        // window.addEventListener('storage', updateCartCount);
      
        // // Clean up the event listener
        // return () => {
        //   window.removeEventListener('storage', updateCartCount);
        // };
        window.addEventListener('cartUpdated', updateCartCount);

        // Clean up
        return () => {
            window.removeEventListener('cartUpdated', updateCartCount);
        };
      }, []);

    return (
    <Paper sx={{
      position: 'fixed', 
      bottom: 0, 
      left: 0, 
      right: 0, 
      backgroundColor: "#fff", 
      zIndex: 2
    }} elevation={10}>
      <BottomNavigation sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Link to={linkTo} style={{ flexGrow: 1, textAlign: 'center', textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="h6" color="textSecondary">
            Store
          </Typography>
        </Link>
        <Link to="/checkout" style={{ flexGrow: 1, textAlign: 'center', textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="h6" color="textSecondary">
            Checkout
          </Typography>
        </Link>
        <Link to="/checkout" style={{ position: 'absolute', right: 0, textDecoration: 'none', color: 'inherit' }}>
          <BottomNavigationAction icon={
            <Badge badgeContent={cartCount} color="primary">
              <ShoppingCartIcon />
            </Badge>
          } />
        </Link>
      </BottomNavigation>
    </Paper>
    )
};

export default CheckoutBar;