import React from 'react';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PhotoReview = ({ userImages, currentIndex, handleCaptionChange, handleFinish, navigateToAddMore, restartUpload }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="83vh" >
      <Card sx={{ maxWidth: '90vw', width: '100%' }}>
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            Review Photos
          </Typography>
          <Typography gutterBottom>
            OK, let's take a look at the photos you've uploaded. Need to change any of the captions?
          </Typography>
          {userImages.map((image, index) => (
            <Box key={index} my={2}>
              <img src={image.preview} alt={`Preview ${index + 1}`} style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                minRows={4}
                maxRows={8}
                value={image.caption}
                onChange={(event) => handleCaptionChange(index, event)}
                placeholder="Edit caption"
              />
            </Box>
          ))}
          <Button variant="contained" color="primary" onClick={handleFinish} fullWidth sx={{ mt: 2 }}>
            Finish
          </Button>
          <Button variant="contained" color="primary" onClick={navigateToAddMore} fullWidth sx={{ mt: 2 }}>
            Add photos
          </Button>
          <Button variant="contained" color="primary" onClick={restartUpload} fullWidth sx={{ mt: 2 }}>
            Restart
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PhotoReview;
