import { BACKEND_BASE_URL, IMAGE_BASE_URL } from './settings';


export function getBackendPath(endpoint) {
  return `${BACKEND_BASE_URL}${endpoint}`;
}

export function getImagePath(imageName) {
  return `${IMAGE_BASE_URL}/images/${imageName}`;
}

export const isLoggedIn = () => {
    const authToken = localStorage.getItem('token');
    return authToken !== null;
  };

export const authGet = async (endpoint) => {
  try {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,  // Adjust this line to your auth method
    }
    const response = await fetch(getBackendPath(endpoint), {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;  // Re-throw the error so the calling function can handle it
  }
};

export function convertS3UrlToHttps(s3Url) {
  // Check if the input is a valid S3 URL
  if (!s3Url.startsWith('s3://')) {
    throw new Error('Invalid S3 URL');
  }

  // Remove the 's3://' prefix
  const s3Path = s3Url.slice(5);

  // Extract the bucket name and object key
  const [bucketName, ...objectKeyParts] = s3Path.split('/');
  const objectKey = objectKeyParts.join('/');

  // Construct the HTTPS URL
  const httpsUrl = `https://${bucketName}.s3.us-east-2.amazonaws.com/${objectKey}`;

  return httpsUrl;
}
