import React from 'react';
import { TextField, Box, Button, Card, CardContent, Typography } from '@mui/material';

const PetInfoForm = ({ petInfo, onInputChange, onComplete }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
        <Card sx={{ maxWidth: '90vw', width: '100%' }}>
          <CardContent>
          <Typography variant="h6" component="div" alignItems="center" gutterBottom>
              Almost done
            </Typography>
            <Typography component="div" alignItems="center" gutterBottom>
              Just a few more questions ...
            </Typography>  
      <Box mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          minRows={1}
          maxRows={1}
          value={petInfo.petName}
          onChange={onInputChange}
          name="petName"
          placeholder="What is your pet's name?"
        />
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          minRows={3}
          maxRows={3}
          value={petInfo.petBreed}
          onChange={onInputChange}
          name="petBreed"
          placeholder="What breed is your pet? (eg. Labrador Retriever, Maine Coon, Mutt, Domestic Shorthair, Unknown, etc.)"
        />
      </Box>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={onComplete} fullWidth>
          All done
        </Button>
      </Box>
      </CardContent>
      </Card>
    </Box>
  );
};

export default PetInfoForm;
