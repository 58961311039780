import React, { useEffect } from 'react';
import { getBackendPath } from '../utilities.js';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import {convertCartToStripeLineItems} from './storeFunctions.js';

  
function PaymentRedirect() {
    
    const redirectToStripe = async () => {
        try {
            const response = await fetch(getBackendPath('/api/checkout/'), {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: convertCartToStripeLineItems()
            });
            const responseData = await response.json();
            if (response.ok && responseData.url) {
              window.location.href = responseData.url;  // Redirect to Stripe checkout
            } else {
              console.error('Failed to create checkout session:', responseData.error);
            }
          } catch (error) {
            console.error('Error initiating Stripe checkout:', error);
          }
        
    }
    // const redirectToStripe = () => {

    //     window.location.href = 'https://buy.stripe.com/eVa6qU6TVctwbLO7ss';
    // };

    // Trigger redirect immediately, or based on some condition or event
    useEffect(() => {
        redirectToStripe();
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
            <Card sx={{ maxWidth: '90vw', width: '100%' }}>
            <CardContent>
                <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="h6" component="div" gutterBottom>
                    Checking out . . .
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                <CircularProgress />
                </Box>
                </CardContent>
            </Card>
        </Box>
    ); // Render nothing or some loading indicator
}

export default PaymentRedirect;