export function addToCart(item, price_format) {
    // Retrieve the existing cart data from localStorage
    // console.log("item");
    // console.log(item);
    // console.log("price_format");
    // console.log(price_format);
    let cart = localStorage.getItem('shoppingCart');
    cart = cart ? JSON.parse(cart) : [];
    // console.log(price_format);
  
    // Check if the item already exists in the cart
    const existingItemIndex = cart.findIndex(cartItem => cartItem.id === item.id && cartItem.price_id === price_format.stripe_price_id);
    
    if (existingItemIndex > -1) {
      // If item exists, update the quantity
      if (price_format.category.quantity_adjustable) cart[existingItemIndex].quantity += 1;
    } else {
      // If item does not exist, add a new entry
      cart.push({
        id: item.id,
        price_id: price_format.stripe_price_id,
        title: item.title,
        price: price_format.size.price,
        // type: type,
        name: item.name,
        description: price_format.category.name,
        size_name: price_format.size.name,
        image: item.image.thumbnail_url,
        quantity_adjustable: price_format.category.quantity_adjustable,
        quantity: 1
      });
    }
  
    // Save the updated cart back to localStorage
    localStorage.setItem('shoppingCart', JSON.stringify(cart));
    window.dispatchEvent(new Event('cartUpdated'));
  }

export function clearCart() {
    console.log("clearCart");
    // Set the cart in localStorage to an empty array
    localStorage.setItem('shoppingCart', JSON.stringify([]));
    
    // Dispatch an event to notify the application that the cart has been cleared
    window.dispatchEvent(new Event('cartUpdated'));
}

export const convertCartToStripeLineItems = () => {
  // Retrieve the cart from local storage
  const cart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
  console.log(cart);
  // Map the cart items to Stripe's line_items format
  const lineItems = cart.map(item => ({
    price: item.price_id, // Use the Stripe price ID assigned to each item
    quantity: item.quantity,
    adjustable_quantity: {
      enabled: true,
      minimum: 0,
      maximum: item.quantity_adjustable ? 10 : 1
    },
    // name: item.name,
    // description: item.description,  // Optional: Include a description if needed
    metadata: null
    // metadata: {
    //   ...item,  // Spread all properties of the item into metadata
    //   price_id: undefined,  // Exclude price_id from metadata since it's used outside
    //   quantity: undefined,  // Exclude quantity as well
    //   description: undefined  // Exclude description if it's used outside metadata
    // }
  }));

  return JSON.stringify({ line_items: lineItems });
};
