import {React, useEffect } from 'react';
import { Container, Grid, Stack, Typography, Button, Box, Card, CardMedia, CardContent,
  useMediaQuery, useTheme
 } from '@mui/material';
import { styled } from '@mui/system';
import { images } from './Images.tsx';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';


// Custom styled components
const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTopSection = styled(Grid)(({ theme }) => ({
  position: 'relative',
  // backgroundImage: `url(${process.env.PUBLIC_URL + '/background-image.jpg'})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
    // Use theme breakpoints for responsive padding
    padding: theme.spacing(6, 2), // Default padding for larger devices
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2), // Reduced vertical padding on devices smaller than 'sm'
    },
  
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#AFCBEE',
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const VideoComponent = ({ src, maxHeight, maxWidth, mobileOnly }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Render conditionally based on whether it's mobile only or desktop only
  if ((mobileOnly && !isMobile) || (!mobileOnly && isMobile)) {
    return null;
  }

  return (
    <video
      src={src}
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: '100%',
        maxHeight: maxHeight || 'auto',
        maxWidth: maxWidth || '100%',
        objectFit: 'contain', // Ensures the video fits within the container while maintaining aspect ratio
        borderRadius: '2px',
        boxShadow: '0 2px 3px rgba(0, 0, 0, 0.2)',
      }}
    />
  );
};


const Landing2 = () => {
    const navigate = useNavigate();
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/landing' });
        ReactGA.event({
            category: 'Funnel',
            action: 'Visited Landing Page',
            label: 'Landing Step'
        });
    }, []);

    const handleClick = () => {
        if (localStorage.getItem('token')) {
            // Track the navigation to the dashboard
            ReactGA.event({
                category: 'Funnel',
                action: 'Navigated to Dashboard',
                label: 'Dashboard Step'
            });
            navigate('/dashboard');
        } else {
            // Track the navigation to the signup page
            ReactGA.event({
                category: 'Funnel',
                action: 'Navigated to Signup Page',
                label: 'Signup Step'
            });
            navigate('/sign_up');
        }
    };
    

    return (
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <StyledTopSection container spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={{ xs: 2, sm: 4 }}>
                  <Typography variant="h3" gutterBottom>
                    Beautiful artwork of <u>your</u> best friend
                  </Typography>
                  <Box display={{ xs: 'block', md: 'none' }} mb={4}>
                    <VideoComponent src={images.video_demo} maxHeight="400px" maxWidth="100%" mobileOnly />
                  </Box>
                  <Typography variant="h5" gutterBottom>
                    Just upload a few photos
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    Our AI creates beautiful artwork
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    Order prints, cards, storybooks, and more
                  </Typography>
                  <Box display="flex" justifyContent={{ xs: 'left', md: 'flex-start' }}>
                    <StyledButton variant="contained" size="large" onClick={handleClick}>
                      Get Started
                    </StyledButton>
                  </Box>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'center',
                  alignItems: 'center',                  
                }}                
              >
                <VideoComponent src={images.video_demo} maxHeight="400px" maxWidth="100%" mobileOnly={false}/>
              </Grid>
            </StyledTopSection>
          </Grid>
          <Grid item xs={12}>
            <StyledGrid container spacing={0.5}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardMedia component="img" height="300" image={images.IMG_2549} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Prints and wall art
                    </Typography>
                    <Typography variant="body1">From $15.99</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image={images.remy_val}
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '300px',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Greeting cards
                    </Typography>
                    <Typography variant="body1">10 for $22.99</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardMedia component="img" height="300" image={images.book1} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Illustrated storybooks
                    </Typography>
                    <Typography variant="body1">Coming soon</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardMedia component="img" height="300" image={images.remy_coloring} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Coloring books
                    </Typography>
                    <Typography variant="body1">Coming soon</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </StyledGrid>
          </Grid>
        </Grid>
      </Container>
    );  
};

export default Landing2;