import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

// Reusable RedirectButton component
const RedirectButton = ({ to, children, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default RedirectButton;