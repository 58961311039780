import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Terms = () => {
  return (
    <Container component="main" maxWidth="md">
      <Paper style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Terms Of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last Updated: 4/15/24
        </Typography>
        <Typography variant="body1" paragraph>
        Welcome, and thank you for your interest in Paw Press AI (“Paw Press AI,” “we,” “us,” or “our”), a service provided by One Time Ventures, LLC. Our website, accessible at www.pawpress.ai, along with our related websites, hosted applications, mobile or other downloadable applications, and other services provided by us (collectively, the “Service”). These Terms of Service are a legally binding contract between you and Paw Press AI regarding your use of the Service.
            PLEASE READ THE FOLLOWING TERMS CAREFULLY: BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING Paw Press AI’S PRIVACY POLICY (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND Paw Press AI’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY Paw Press AI AND BY YOU TO BE BOUND BY THESE TERMS.
            ARBITRATION NOTICE. EXCEPT FOR CERTAIN KINDS OF DISPUTES DESCRIBED IN SECTION 18 (DISPUTE RESOLUTION AND ARBITRATION), YOU AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND BY ACCEPTING THESE TERMS, YOU AND Paw Press AI ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Paw Press AI Service Overview
        </Typography>
        <Typography variant="body1" paragraph>
        Paw Press AI is a platform designed to boost the creation and exploration of AI models and AI-generated media. We offer an environment where users of the Service (“Users”) may be able to generate images (“Generated Images”) on the Service, as well as order printed copies of those images.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Eligibility
        </Typography>
        <Typography variant="body1" paragraph>
        You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Accounts and Registration.
        </Typography>
        <Typography variant="body1" paragraph>
        To access most features of the Service, you must register for an account, whether directly or through a third party integration (“Account”). When you register for an Account, you may be required to provide us with some information about yourself, such as your name, username, email address, or other contact information. You agree that the information you provide to us is accurate, complete, and not misleading, and that you will keep it accurate and up to date at all times. When you register, you will be asked to create a password. You are solely responsible for maintaining the confidentiality of your Account and password, and you accept responsibility for all activities that occur under your Account. If you believe that your Account is no longer secure, then you should immediately notify us at support@pawpress.ai.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        General Payment Terms.
        </Typography>
        <Typography variant="body1" paragraph>
        Certain features of the Service may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. Unless otherwise specifically provided for in these Terms, all fees are in U.S. Dollars.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        5.1 Price.
        </Typography>
        <Typography variant="body1" paragraph>
        Paw Press AI reserves the right to determine pricing for the Service. Paw Press AI will make reasonable efforts to keep pricing information published on the Service up to date. We encourage you to check our pricing page(s) periodically for current pricing information. Paw Press AI may change the fees for any feature of the Service, including additional fees or charges, if Paw Press AI gives you advance notice of changes before they apply. Paw Press AI, at its sole discretion, may make promotional offers with different features and different pricing to any of Paw Press AI’s customers. These promotional offers, unless made to you, will not apply to you.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        5.2 Refund.
        </Typography>
        <Typography variant="body1" paragraph>
        As a customer, you are eligible for a refund in the following situations:
        Recurring Technical Problems: If ongoing technical difficulties hinder your use of the platform.
        Flawed Resource: If digital or physical products you've purchased are substantially flawed beyond what could be reasonably attributed to user error.
        We retain the right to refuse a refund in the event:

        You were muted or removed from the platform for breaking content guidelines
        You are unable to get results to your satisfaction through no technical fault of the platform.
        You have changed your mind.
        Paw Press AI will grant you a refund if any User Content you purchased through the Service was removed or taken down from the Service by us or by the Creator in compliance with the applicable requirements under the Digital Millennium Copyright Act.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        5.3 Payment Processing.
        </Typography>
        <Typography variant="body1" paragraph>
        Paw Press AI may contract with a third party to process payments submitted through the Service (the “Payment Processor”). Users may be required to provide their payment method details to the Payment Processor. Before you pay through the Payment Processor, you will have an opportunity to review the amounts that you will be charged.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        5.4 Authorization.
        </Typography>
        <Typography variant="body1" paragraph>
        You authorize Paw Press AI to charge all sums for the orders or purchases that you make and any level of Service you select as described in these Terms or published by Paw Press AI, including all applicable taxes, to the payment method specified in your Account or otherwise provided to us. If you pay any fees with a credit card, then Paw Press AI may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        5.5 Delinquent Accounts.
        </Typography>
        <Typography variant="body1" paragraph>
        Paw Press AI may suspend or terminate access to the Service, including fee-based portions of the Service, for any account for which any amount is due but unpaid. In addition to the amount due for the Service, a delinquent account will be charged with fees or charges that are incidental to any chargeback or collection of any the unpaid amount, including collection fees. If your payment method is no longer valid at the time a renewal Subscription Fee is due, then Paw Press AI reserves the right to delete your account and any information or User Content (defined below) associated with your Account without any liability to you.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Licenses
        </Typography>
        <Typography variant="body1" paragraph>
        6.1 Limited License.
        Subject to your complete and ongoing compliance with these Terms, Paw Press AI grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to: (a) install and use one object code copy of any mobile or other downloadable application associated with the Service (whether installed by you or pre-installed on your mobile device by the device manufacturer or a wireless telephone provider) on a mobile device that you own or control; and (b) access and use the Service.
        6.2 License Restrictions.
        Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, publicly perform, or create derivative works of the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, then you may not use it.
        6.3 Feedback. 
        We respect and appreciate the thoughts and comments from our Users If you choose to provide input and suggestions regarding existing functionalities, problems with or proposed modifications or improvements to the Service (“Feedback”), then you hereby grant Paw Press AI an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right and license to exploit the Feedback in any manner and for any purpose, including to improve the Service and create other products and services. We will have no obligation to provide you with attribution for any Feedback you provide to us.
        </Typography>
        <Typography variant="body1" paragraph>
        Ownership; Proprietary Rights. The Service is owned and operated by Paw Press AI. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Service provided by Paw Press AI (“Materials”) are protected by intellectual property and other laws. “Materials” does not include any User Content Posted by you or other Users. All Materials included in the Service are the property of Paw Press AI or its third-party licensors. Except as expressly authorized by Paw Press AI, you may not make use of the Materials. There are no implied licenses in these Terms and Paw Press AI reserves all rights to the Materials not granted expressly in these Terms.
        </Typography>
        <Typography variant="body1" paragraph>
Third-Party Terms

8.1 Third-Party Services and Linked Websites. Paw Press AI may provide tools through the Service that enable you to export information, including User Content, to third-party services, including through features that allow you to link your Account on the Service with an account on the third-party service, or through our implementation of third-party buttons (such as “like” or “share” buttons); provided, however, that you will not use the export functionality in violation of any license terms associated with such User Content. By using one of these tools, you hereby authorize Paw Press AI to transfer that information to the applicable third-party service. Third-party services are not under Paw Press AI’s control, and, to the fullest extent permitted by law, Paw Press AI is not responsible for any third-party service’s use of your exported information. The Service may also contain links to third-party websites. Linked websites are not under Paw Press AI’s control, and Paw Press AI is not responsible for their content. Please be sure to review the terms of use and privacy policy of any third-party services before you share any User Content or information with such third-party services. Once sharing occurs, Paw Press AI will have no control over the information that has been shared.

8.2 Third-Party Software. The Service may include or incorporate third-party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (“Third-Party Components”). Although the Service is provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third-Party Components under the applicable third-party licenses or to limit your use of Third-Party Components under those third-party licenses.
</Typography>
<Typography variant="body1" paragraph>
User Content
9.1 User Content Generally. Certain features of the Service may permit Users to submit, upload, publish, broadcast, or otherwise transmit (“Post”) content to the Service, including reviews, photos, video or audio (including sound or voice recordings and musical recordings embodied in the video or audio), images, Models, Generated Images, algorithms, folders, data, text, and any other works of authorship or other works (“User Content”). You retain any copyright and other proprietary rights that you may hold in the User Content that you Post to the Service, subject to the licenses granted in these Terms.

9.2 Limited License Grant to Paw Press AI. By Posting User Content to or via the Service, you grant Paw Press AI a worldwide, non-exclusive, irrevocable, royalty-free, fully paid right and license (with the right to sublicense through multiple tiers) to host, store, transfer, publicly display, publicly perform (including by means of a digital audio transmission), communicate to the public, reproduce, modify for the purpose of formatting for display, create derivative works as authorized in these Terms, and distribute your User Content, in whole or in part, in any media formats and through any media channels, in each instance whether now known or hereafter developed. You agree to pay all monies owing to any person or entity resulting from Posting your User Content and from Paw Press AI’s exercise of the license set forth in this Section. You agree that if you set any of your User Content as “public,” your User Content may be viewed by other Users, and you hereby grant each User a perpetual, irrevocable, worldwide, royalty-free, non-exclusive license to use, display, publish, reproduce, distribute, and make derivative works of that User Content through the Service, unless a another license is specified by you on your page for your User Content. If you set your User Content as “private,” we will use commercially reasonable measures designed to keep such User Content confidential and protected from unauthorized access or disclosure by a third party, subject to our rights to access and use such User Content under these Terms and our Privacy Policy.

9.3 You Must Have Rights to the Content You Post; User Content Representations and Warranties. You must not Post User Content if you are not the owner of or are not fully authorized to grant rights in all of the elements of that User Content as set forth in these Terms. Paw Press AI disclaims any and all liability in connection with User Content. You are solely responsible for (i) your User Content and the consequences of providing User Content via the Service, and (ii) all license terms related to User Content. By providing User Content via the Service, you affirm, represent, and warrant to us that:

(a) you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize Paw Press AI and Users in the manner contemplated by Paw Press AI, the Service, and these Terms;
(b) your User Content, and the Posting or other use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, misappropriate, or otherwise breach any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property, contract, or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause Paw Press AI to violate any law or regulation or require us to obtain any further licenses from or pay any royalties, fees, compensation or other amounts or provide any attribution to any third parties; and
(c) your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.


9.5 User Content Disclaimer. We are under no obligation to edit or control User Content that you or other Users Post and will not be in any way responsible or liable for User Content. Paw Press AI may, however, at any time and without prior notice, screen, remove, edit, or block any User Content that in our sole judgment violates these Terms, is alleged to violate the rights of third parties, or is otherwise objectionable. You understand that, when using the Service, you will be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Paw Press AI with respect to User Content.

9.6 Content Moderation. Paw Press AI does not control and does not have any obligation to monitor: (a) User Content; (b) any content made available by third parties; or (c) the use of the Service by its Users. You acknowledge and agree that Paw Press AI reserves the right to, and may from time to time, monitor any and all information transmitted or received through the Service for operational and other purposes and moderate all User Content on the Services, at its discretion. If at any time Paw Press AI chooses to monitor or moderate the content, then Paw Press AI still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our Privacy Policy (defined below).

Moderators may flag content or Users at their reasonable discretion. If any User Content is flagged, our moderators will consider context and intent while enforcing these Terms and our policies. If notified by a User or content owner that User Content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the User Content, which we reserve the right to do at any time and without notice. For clarity, Paw Press AI does not permit infringing activities on the Service. Paw Press AI may block, filter, mute, remove or disable access to any User Content uploaded to or transmitted through the Service without any liability to the User who Posted such User Content to the Service or to any other Users.

The following User Content is expressly prohibited and may be flagged by or for our moderators:

(a) Content depicting or intended to depict real individuals or minors (under 18) in a mature context. For a more detailed explanation, please review our safety center for guidelines on depicting the likeness of real people and depicting minors;

(b) Content depicting or intended to depict photorealistic minors, regardless of context;

(c) Content promoting illegal or violent activities;

(d) Content falsely labeled as having been created or endorsed by another creator, or impersonates another creator on the Service. For additional information about how content is moderated, please review our Content Moderation Policy.

Any content that violates one of the following terms above but has been self-censored using black bars, blurring, or other means may also be flagged or removed.

9.7 Reporting and Appeals. You are encouraged to report any inappropriate User Content that violate these Terms or our published policies. Reports can be submitted through our standard reporting mechanism, and should include relevant information to assist in the investigation. We reserve the right to suspend or terminate the accounts of Users who are found to be repeatedly violating these Terms or our published policies. Users can request an explanation or appeal a moderation decision by submitting an appeal through our Appeal Form for further review.
</Typography>
<Typography variant="body1" paragraph>
Communications
Email. We may send you emails concerning our products and services, as well as those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the promotional email itself.
</Typography>
<Typography variant="body1" paragraph>
Prohibited Conduct. BY USING THE SERVICE, YOU AGREE NOT TO:
11.1 use the Service for any illegal purpose or in violation of any local, state, national, or international law;

11.2 harass, threaten, demean, embarrass, bully, or otherwise harm any other User;

11.3 violate, encourage others to violate, or provide instructions on how to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right;

11.4 access, search, or otherwise use any portion of the Service through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, and data mining tools) other than the software or search agents provided by Paw Press AI;

11.5 interfere with security-related features of the Service, including by: (i) disabling or circumventing features that prevent or limit use, printing or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the Service except to the extent that the activity is expressly permitted by applicable law;

11.6 interfere with the operation of the Service or any User’s enjoyment of the Service, including by: (i) uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer or advertisement to another User; (iii) collecting personal information about another User or third party without consent; or (iv) interfering with or disrupting any network, equipment, or server connected to or used to provide the Service;

11.7 perform any fraudulent activity including impersonating any person or entity, claiming a false affiliation or identity, accessing any other Service account without permission;

11.8 sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section 7 (Ownership; Proprietary Rights)) or any right or ability to view, access, or use any Materials; or

11.9 attempt to do any of the acts described in this Section 11 (Prohibited Conduct) or assist or permit any person in engaging in any of the acts described in this Section 11 (Prohibited Conduct).
</Typography>
<Typography variant="body1" paragraph>
Modification of Terms. We may, from time to time, change these Terms. Please check these Terms periodically for changes. Revisions will be effective immediately except that, for existing Users, material revisions will be effective 30 days after posting or notice to you of the revisions unless otherwise stated. We may require that you accept modified Terms in order to continue to use the Service. If you do not agree to the modified Terms, then you should remove your User Content and discontinue your use of the Service. Except as expressly permitted in this Section 13 (Modification of Terms), these Terms may be amended only by a written agreement signed by authorized representatives of the parties to these Terms.

Term, Termination, and Modification of the Service

14.1 Term. These Terms are effective beginning when you accept the Terms or first download, install, access, or use the Service, and ending when terminated as described in Section 14.2 (Termination).

14.2 Termination. If you violate any provision of these Terms, then your authorization to access the Service and these Terms automatically terminate. In addition, Paw Press AI may, at its sole discretion, terminate these Terms or your Account on the Service, or suspend or terminate your access to the Service, at any time for any reason or no reason, with or without notice, and without any liability to you arising from such termination. You may terminate your Account and these Terms at any time by following the instructions in your Account or contacting customer service at support@pawpress.ai.

14.3 Effect of Termination. Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the Service; (b) you will no longer be authorized to access your Account or the Service; (c) you must pay Paw Press AI any unpaid amount that was due prior to termination; and (d) all payment obligations accrued prior to termination and Sections 6.3 (Feedback), 7 (Ownership; Proprietary Rights), 14.3 (Effect of Termination), 15 (Indemnity), 16 (Disclaimers; No Warranties by Paw Press AI), 17 (Limitation of Liability), 18 (Dispute Resolution and Arbitration), and 19 (Miscellaneous) will survive. You are solely responsible for retaining copies of any User Content you Post to the Service since upon termination of your Account, you may lose access rights to any User Content you Posted to the Service. If your Account has been terminated for a breach of these Terms, then you are prohibited from creating a new Account on the Service using a different name, email address or other forms of Account verification.

14.4 Modification of the Service. Paw Press AI reserves the right to modify or discontinue all or any portion of the Service at any time (including by limiting or discontinuing certain features of the Service), temporarily or permanently, without notice to you. Paw Press AI will have no liability for any change to the Service, including any paid-for functionalities of the Service, or any suspension or termination of your access to or use of the Service. You should retain copies of any User Content you Post to the Service so that you have permanent copies in the event the Service is modified in such a way that you lose access to User Content you Posted to the Service.

Indemnity. To the fullest extent permitted by law, you are responsible for your use of the Service, and you will defend and indemnify Paw Press AI, its affiliates and their respective shareholders, directors, managers, members, officers, employees, consultants, and agents (together, the “Paw Press AI Entities”) from and against every claim brought by a third party, and any related liability, damage, loss, and expense, including attorneys’ fees and costs, arising out of or connected with: (1) your unauthorized use of, or misuse of, the Service, including User Content of other Users; (2) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (3) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (4) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those claims.

Disclaimers; No Warranties by Paw Press AI

16.1 THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. Paw Press AI DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. Paw Press AI DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND Paw Press AI DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.

16.2 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR Paw Press AI ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE Paw Press AI ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.

16.3 THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION 16 (DISCLAIMERS; NO WARRANTIES BY Paw Press AI) APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. Paw Press AI does not disclaim any warranty or other right that Paw Press AI is prohibited from disclaiming under applicable law.

Limitation of Liability
17.1 TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE Paw Press AI ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY Paw Press AI ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.

17.2 EXCEPT AS PROVIDED IN SECTIONS 18.5 (COMMENCING ARBITRATION) AND 18.7 (ARBITRATION RELIEF) AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE Paw Press AI ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO Paw Press AI FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO THE CLAIM AND (b) US$100.

17.3 EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 17 (LIMITATION OF LIABILITY) WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.

Dispute Resolution and Arbitration
18.1 Generally. Except as described in Section 18.2 (Exceptions) and 18.3 (Opt-Out), you and Paw Press AI agree that every dispute arising in connection with these Terms, the Service, or communications from us will be resolved through binding arbitration. Arbitration uses a neutral arbitrator instead of a judge or jury, is less formal than a court proceeding, may allow for more limited discovery than in court, and is subject to very limited review by courts. This agreement to arbitrate disputes includes all claims whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. Any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement will be resolved by the arbitrator. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND Paw Press AI ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.

18.2 Exceptions. Although we are agreeing to arbitrate most disputes between us, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.

18.3 Arbitrator. This arbitration agreement, and any arbitration between us, is subject the Federal Arbitration Act and will be administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting Paw Press AI.

18.4 Arbitration Proceedings. Any arbitration hearing will take place in the county and state of your [residence/billing address] unless we agree otherwise or, if the claim is for US$10,000 or less (and does not seek injunctive relief), you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a telephonic or video hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your [residence/billing address]. During the arbitration, the amount of any settlement offer made by you or Paw Press AI must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based.

18.5 Arbitration Relief. Except as provided in Section 18.8 (No Class Actions), the arbitrator can award any relief that would be available if the claims had been brought in a court of competent jurisdiction. If the arbitrator awards you an amount higher than the last written settlement amount offered by Paw Press AI before an arbitrator was selected, Paw Press AI will pay to you the higher of: (a) the amount awarded by the arbitrator and (b) US$10,000. The arbitrator’s award shall be final and binding on all parties , except (1) for judicial review expressly permitted by law or (2) if the arbitrator's award includes an award of injunctive relief against a party, in which case that party shall have the right to seek judicial review of the injunctive relief in a court of competent jurisdiction that shall not be bound by the arbitrator's application or conclusions of law. Judgment on the award may be entered in any court having jurisdiction.

18.6 No Class Actions. YOU AND Paw Press AI AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Paw Press AI agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.

18.7 Modifications to this Arbitration Provision. If Paw Press AI makes any substantive change to this arbitration provision, you may reject the change by sending us written notice within 30 days of the change to Paw Press AI’s address for Notice of Arbitration, in which case your Account with Paw Press AI will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.

18.8 Enforceability. If Section 18.6 (No Class Actions) or the entirety of this Section 18 (Dispute Resolution and Arbitration) is found to be unenforceable, or if Paw Press AI receives an Opt-Out Notice from you, then the entirety of this Section 18 (Dispute Resolution and Arbitration) will be null and void and, in that case, the exclusive jurisdiction and venue described in Section 19.2 (Governing Law) will govern any action arising out of or related to these Terms.
</Typography>
<Typography variant="body1" paragraph>
Miscellaneous
19.1 General Terms. These Terms, including the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and Paw Press AI regarding your use of the Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms and all rights granted under these Terms, including with respect to your User Content, at any time without notice or consent. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of Section headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word “including” means “including but not limited to.” If any part of these Terms is held to be invalid or unenforceable, then the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.

19.2 Governing Law. These Terms are governed by the laws of the State of Delaware without regard to conflict of law principles. You and Paw Press AI submit to the personal and exclusive jurisdiction of the state courts and federal courts located within Kent County, Delaware for resolution of any lawsuit or court proceeding permitted under these Terms. We operate the Service from our offices in the United States, and we make no representation that Materials included in the Service are appropriate or available for use in other locations.

19.3 Privacy Policy. Please read the Paw Press AI Privacy Policy (the “Privacy Policy”) carefully for information relating to our collection, use, storage, and disclosure of your personal information. The Paw Press AI Privacy Policy is incorporated by this reference into, and made a part of, these Terms.

19.4 Additional Terms. Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post on or link to from the Service (the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.

19.5 Consent to Electronic Communications. By using the Service, you consent to receiving certain electronic communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.

19.6 Contact Information. The Service is offered by One Time Ventures LLC. You may contact us by sending correspondence to that address or by emailing us at support@pawpress.ai.

19.7 No Support. We are under no obligation to provide support for the Service. In instances where we may offer support, the support will be subject to published policies.

19.8 International Use. The Service is intended for visitors located within the United States. We make no representation that the Service is appropriate or available for use outside of the United States. Access to the Service from countries or territories or by individuals where such access is illegal is prohibited.
</Typography>

      </Paper>
    </Container>
  );
}

export default Terms;
