import {React, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {getBackendPath} from './utilities';
import ReactGA from 'react-ga4';

const Auth = () => {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/sign_up' });
        ReactGA.event({
            category: 'Funnel',
            action: 'Visited Signup Page',
            label: 'Signup Step'
        });
    }, []);
    const navigate = useNavigate(); // Use the useNavigate hook here

    // Define handleSocialLogin inside the component to have access to navigate
    const handleSocialLogin = async (provider, response) => {
        const auth_token = response.credential; // This might vary depending on the provider and the response structure

        try {
            // console.log(response);
            // console.log(JSON.stringify({ auth_token }));
            const result = await fetch(getBackendPath(`/api/auth/social/${provider}/`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ auth_token }),
            });

            const data = await result.json();
            if (result.ok) {
                // localStorage.setItem('token', data.token);
                // localStorage.setItem('user_state', data.user_state);
                Object.keys(data).forEach(key => {
                    // Store each key-value pair in localStorage
                    console.log([key, data[key]]);
                    localStorage.setItem(key, (data[key]));
                });    
                navigate('/dashboard'); // Use navigate here
            } else {
                throw new Error(data.error || 'Login failed');
            }
        } catch (error) {
            console.error('Request failed', error);
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
            <GoogleLogin
                onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                    handleSocialLogin("google", credentialResponse);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                // useOneTap
            />
        </Box>
    );
};

export default Auth;
