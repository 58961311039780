/* eslint-disable */
import React from 'react';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" component="span">
      {'Paw Press AI © '}
      {new Date().getFullYear()}
      {' | '}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box sx={{ padding: 4, width: '100%' }} component="footer" position="relative" textAlign="center">
      <Typography variant="body2" color="text.secondary" align="center">
        <Copyright />
        <Link color="inherit" href="/terms">
          Terms
        </Link>
        {' | '}
        <Link color="inherit" href="/privacy">
          Privacy
        </Link>
      </Typography>
    </Box>
  )
}
