import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

function usePageTracking() {
  let location = useLocation();

  useEffect(() => {
    // Assuming you've initialized ReactGA somewhere in your app, like in index.js
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
}

export default usePageTracking;
