/* eslint-disable */
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PetsIcon from '@mui/icons-material/Pets';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NavMenu from './NavMenu';
import {isLoggedIn} from '../utilities';

export default function Navbar({ item_list }) {
  const destination = isLoggedIn() ? "/dashboard" : "/";

  return (
    <AppBar sx={{ background: "#fff", borderBottom: "1px solid #ccc" }} position="sticky" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between'}}>
        {/* <Link href={destination} color="#111" underline="none">
          <PetsIcon sx={{ mr: 2 }} />
        </Link> */}
        <Link href={destination} color="#111" underline="none">
          <Typography variant="h5" color="inherit" noWrap>
            Paw Press AI
          </Typography>
        </Link>

        <NavMenu sx={{color: "#111"}} item_list={item_list} />
      </Toolbar>
    </AppBar>
  );
}
