import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';

const PhotoDropzone = ({ onFilesAccepted, isMobile, currentIndex }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFilesAccepted, accept: 'image/*', multiple: true });

  return (
    <Box {...getRootProps()} p={5} border="1px dashed" borderColor="grey.400" borderRadius="borderRadius" bgcolor="background.paper" style={{ cursor: 'pointer', textAlign: 'center', minHeight: '150px' }}>
      <input {...getInputProps()} />
      <Typography>{isMobile ? currentIndex > 0 ?  "Tap here to add more photos." : "Tap here to select photos." : "Drag and drop some photos here, or click to select photos."}</Typography>
      <Typography variant="body2">(10-15 photos are needed. Try to use a variety of poses and settings in the photos.)</Typography>
    </Box>
  );
};

export default PhotoDropzone;
