import React from 'react';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import {images} from '../Images';

const PhotoExample = ({ navigateBack, handleNext }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
    <Card sx={{ maxWidth: '90vw', width: '100%' }}>
        <CardContent>
        <Typography variant="h6" component="div" alignItems="center" gutterBottom>
            Example
        </Typography>
        <Typography component="div" alignItems="center" gutterBottom>
            Here's an example of how to write a caption for your photos. The more details you add the better our AI can understand the photo. In this example, our dog is named Remy.
        </Typography>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
            <img src={images.IMG_8800_small} alt={`Example image`} style={{ width: '100%', maxHeight: '40vh', objectFit: 'contain' }} />
        </Box>
        <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            value="Remy sitting outside with red and white flowers in the background, 
            wearing blue collar and green harness and leash, squinting in the sunlight, looking at the camera."
            multiline
            disabled
            minRows={2} // Minimum number of lines the text area will occupy
            maxRows={6} // Maximum number of lines the text area can expand to
            // color="secondary" 
        />
        <Typography component="div" alignItems="center" gutterBottom>
            Now it's your turn.
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" onClick={navigateBack}>
                Back
            </Button>
            <Button color="primary" variant="contained" onClick={handleNext}>
                Next
            </Button>
          </Box>
        </CardContent>
        
    </Card>
    </Box>
  );
};

export default PhotoExample;
