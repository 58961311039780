import { useState } from "react";
import { getBackendPath } from "../utilities.js";

const usePhotoUpload = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [uploadError, setUploadError] = useState(false);

    const getPresignedUrls = async (petInfo) => {
        setError(null);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No auth token found');

            const response = await fetch(getBackendPath(`/api/images/presigned_urls/?pet_name=${petInfo.petName}&pet_breed=${petInfo.petBreed}`), {
                headers: { 'Authorization': `Token ${token}` }
            });

            if (!response.ok) throw new Error('Failed to get presigned URLs');

            const data = await response.json();
            return data.urls;
        } catch (error) {
            setError(error.message);
            setUploadError(true);
            console.error('Error:', error);
        } 
    };

    const uploadFiles = async (files, urls) => {
        console.log("checking urls");
        if (files.length >= urls.length) {
            setError("The number of files doesn't match the number of URLs provided.");
            return;
        }

        const uploadProgressArray = new Array(files.length).fill(0);

        const updateTotalProgress = () => {
            const totalProgress = uploadProgressArray.reduce((acc, cur) => acc + cur, 0) / files.length;
            setUploadProgress(totalProgress);
        };

        const uploadPromises = files.map((file, index) => {
            const url = urls[index].url; // Assuming each URL is an object with a 'url' property
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', url);

                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const fileProgress = (event.loaded / event.total) * 100;
                        uploadProgressArray[index] = fileProgress;
                        updateTotalProgress();
                    }
                };

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        uploadProgressArray[index] = 100; // Ensure complete progress for this file
                        updateTotalProgress();
                        resolve(url);
                    } else {
                        reject(new Error(`Failed to upload file ${index + 1}: Server responded with status ${xhr.status}`));
                    }
                };

                xhr.onerror = () => {
                    reject(new Error(`Failed to upload file ${index + 1}: An error occurred during the upload.`));
                };

                xhr.setRequestHeader('Content-Type', 'image/jpeg'); // Modify as needed depending on the file type
                console.log('sending uploads.');
                xhr.send(file.file);
            });
        });

        try {
            console.log('Starting uploads.');
            const uploadedUrls = await Promise.all(uploadPromises);
            console.log('All files have been uploaded successfully.');
            setUploadProgress(100);
            return uploadedUrls;
        } catch (error) {
            console.error('Upload error:', error);
            setError(`Upload failed: ${error.message}`);
            setUploadError(true);
        }
    };

    const finalizeUpload = async (uploadedUrls, images, petInfo) => {
        // Prepare the JSON payload with image URLs
        const imageData = images.map((image, index) => ({
            url: uploadedUrls[index],  // Each URL from the successful upload
            caption: image.caption
        }));

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No auth token found');

            const response = await fetch(getBackendPath(`/api/images/complete/`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    images: imageData,
                    pet_name: petInfo.petName,
                    pet_breed: petInfo.petBreed
                })
            });

            if (!response.ok) {
                throw new Error('Failed to finalize upload');
            }

            const responseData = await response.json();
            console.log('Upload complete:', responseData);
            localStorage.setItem('photo_upload_completed', 'true');
            return responseData;
        } catch (error) {
            setError(`Finalization failed: ${error.message}`);
            console.error('Finalization error:', error);
            setUploadError(true);
        }
    };

    return { uploadProgress, uploadError, error, getPresignedUrls, uploadFiles, finalizeUpload };
};



export default usePhotoUpload;
