import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, Box, Dialog } from '@mui/material';
// import { images as static_images } from '../Images.tsx';
// import SearchBar from 'material-ui-search-bar'; // You might need to install this component
import {addToCart} from './storeFunctions';
import CheckoutBar from './CheckoutBar.tsx';
import { getBackendPath } from '../utilities';

const StoreCategory = ({ title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [products, setProducts] = useState([]); 
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchProductCategories = async () => {
      const pathParts = location.pathname.split('/');
      const category = pathParts[pathParts.length - 1];
      
      try {
        const response = await fetch(getBackendPath(`/api/products/${category}/`), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`,  // Adjust this line to your auth method
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setProducts(data);  // Store the data in state
        console.log(data);
      } catch (error) {
        console.error('Error fetching product categories:', error);
      }
    };

    fetchProductCategories();
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }} display="flex">
        <Typography variant="h4" color="textSecondary">
            {title}
        </Typography> 
      </Box>
      <Grid container spacing={2} style={{ padding: '10px' }}>
      {products.map((storeItem, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardMedia
              component="img"
              style={{
                height: '300px',
                objectFit: 'contain' // Ensures the entire image is shown within the defined area
              }}
              image={storeItem.image.thumbnail_url}
              alt={storeItem.name}
              onClick={() => handleClickOpen(storeItem.image.watermarked_url)}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {storeItem.name}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '8px 16px', // Ensures proper padding for better touch interaction
              }}
              disableSpacing={true}
            >
              {storeItem.format_offerings.sort((a, b) => a.format.price - b.format.price) .map((format, index) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                    alignItems: 'center',
                  }}
                  key={index}
                >
                  <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
                    {format.format.name}: ${format.format.price} <br></br>
                    {format.format.description}
                  </Typography>
                  <Button variant="contained" color="primary" size="small" onClick={() => addToCart(storeItem, format)}>
                    Add
                  </Button>
                </div>
              ))}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
    {/* Modal for displaying the image */}
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <img
          src={selectedImage}
          alt="Selected"
          style={{
            maxWidth: '100vw',
            maxHeight: '100vh',
            objectFit: 'contain',
          }}
        />
      </Dialog>
      
    <CheckoutBar/>
    </>
  );
};

export default StoreCategory;
