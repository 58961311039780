import React, { useEffect, useState } from 'react';
import { Button, Typography, Card, CardContent, Box, CircularProgress, LinearProgress } from '@mui/material';
import RedirectButton from './RedirectButton.tsx';
// import { useNavigate } from 'react-router-dom';
// import useFetch from './useFetch.ts';
import {authGet} from './utilities';
import ReactGA from 'react-ga4';


const CardComponent = ({ step, title, description, buttonLabel, buttonTo, isDisabled, isLoading }) => (
  <Card sx={{ maxWidth: '90vw', width: '100%', margin: step == 2 ? '20px' : '0px' }}>
    <CardContent>
      <Typography variant="h6" component="div" alignItems="center" gutterBottom>
        {title}
      </Typography>
      <Typography component="div" alignItems="center" gutterBottom>
        {description}
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        {buttonTo ? (
          <RedirectButton to={buttonTo} color="primary" variant="contained" disabled={isDisabled}>
            {isLoading ? <GradientCircularProgress/>: ""}
            {buttonLabel}
          </RedirectButton>
        ) : (
          <Box position="relative" display="inline-block">
            <Button color="primary" variant="contained" disabled={isDisabled}>
              <Box px={2}>  {/* This adds horizontal padding */}
                {buttonLabel}
              </Box>
            </Button>
            {isLoading && (
              <LinearProgress
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 4,
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </CardContent>
  </Card>
);

// From https://github.com/mui/material-ui/issues/9496#issuecomment-959408221
function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
  );
}

const Dashboard = () => {
    const [isPhotoUploadCompleted, setIsPhotoUploadCompleted] = useState(false);
    const [isModelTrainingCompleted, setIsModelTrainingCompleted] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isStoreAvailable, setIsStoreAvailable] = useState(false);
    const [userSlug, setUserSlug] = useState(false);

    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: '/dashboard' });
      ReactGA.event({
          category: 'Funnel',
          action: 'Visited Dashboard',
          label: 'Dashboard Step'
      });
      // Fetch the user state from the API
        const fetchUserState = async () => {
          try {
              const userState = await authGet('/api/userstate/'); // Update with your actual endpoint
              

              // Update the component state based on the fetched user state
              setIsPhotoUploadCompleted(userState.photo_upload_completed);
              setIsModelTrainingCompleted(userState.model_training_completed);
              setIsStoreAvailable(userState.store_population_completed);
              setIsGenerating(userState.photo_upload_completed && 
                              !userState.model_training_completed && 
                              !userState.store_population_completed);

              // Optionally, save the state to localStorage if needed
              localStorage.setItem('photo_upload_completed', userState.photo_upload_completed);
              localStorage.setItem('model_training_completed', userState.model_training_completed);
              localStorage.setItem('store_population_completed', userState.store_population_completed);
              const photoUploadCompleted = localStorage.getItem('photo_upload_completed') === 'true';
              const modelTrainingCompleted = localStorage.getItem('model_training_completed') === 'true';
              const isStoreAvailable = localStorage.getItem('store_population_completed') === 'true';
              console.log([photoUploadCompleted, modelTrainingCompleted, isStoreAvailable]);
              const userSlug = await authGet('/api/user_slug/')
              setUserSlug(userSlug.slug);

          } catch (error) {
              console.error('Failed to fetch user state:', error);
          }
      };

      fetchUserState();
// }, []);
      // Fetch status from localStorage
      // const photoUploadCompleted = localStorage.getItem('photo_upload_completed') === 'true';
      // const modelTrainingCompleted = localStorage.getItem('model_training_completed') === 'true';
      // const isStoreAvailable = localStorage.getItem('store_population_completed') === 'true';

      // setIsPhotoUploadCompleted(photoUploadCompleted);
      // setIsModelTrainingCompleted(modelTrainingCompleted);
      // setIsGenerating(photoUploadCompleted && !modelTrainingCompleted && !isStoreAvailable);
      // setIsStoreAvailable(isStoreAvailable);
      // console.log([photoUploadCompleted, modelTrainingCompleted, isStoreAvailable]);
    }, []);

    // Determine the button label for the second component
    let secondButtonLabel = 'Waiting';
    if (isPhotoUploadCompleted && !isModelTrainingCompleted && !isStoreAvailable) {
      secondButtonLabel = 'Generating';
    } else if (isStoreAvailable) {
      secondButtonLabel = 'Complete';
    }

    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="82vh">
        <CardComponent
          step={1}
          title="Step 1: AI Model"
          description="First, let's create an AI model using pictures of your pet. All you need is 10 pictures and about 5 minutes."
          buttonLabel="Create Model"
          buttonTo='/photo_upload'
          isDisabled={false}
          isLoading={false}
        />
        <CardComponent
          step={2}
          title="Step 2: Image Generation"
          description="After creating the AI model, we'll start generating beautiful artwork of your pet. Just sit back and relax, and we'll email you when they're ready."
          buttonLabel={secondButtonLabel}
          buttonTo={false}
          isDisabled={true}
          isLoading={isGenerating}
        />
        <CardComponent
          step={3}
          title="Step 3: Order"
          description="Keep an eye out for our email, then come back here to order story books, prints, and more featuring your pet!"
          buttonLabel="Store"
          buttonTo={userSlug ? `/store/${userSlug}` : "/store"}
          isDisabled={!isStoreAvailable}
          isLoading={false}
        />
      </Box>
    );
};

export default Dashboard;
