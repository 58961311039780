import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Button,
    Box,
    Divider,
    Avatar,
    Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckoutBar from './CheckoutBar.tsx';

const Checkout = () => {
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
        setCart(storedCart);

        const handleCartUpdated = () => {
            const updatedCart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
            setCart(updatedCart);
        };

        window.addEventListener('cartUpdated', handleCartUpdated);

        return () => {
            window.removeEventListener('cartUpdated', handleCartUpdated);
        };
    }, []);

    const handleQuantityChange = (index, delta) => {
        const updatedCart = [...cart];
        if (updatedCart[index].quantity_adjustable) {
            updatedCart[index].quantity += delta;
            if (updatedCart[index].quantity < 1) handleRemoveItem(index);
            else {
                setCart(updatedCart);
                localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
                window.dispatchEvent(new Event('cartUpdated'));
            }
            
        }
    };

    const handleRemoveItem = (index) => {
        const updatedCart = cart.filter((_, i) => i !== index);
        setCart(updatedCart);
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
        window.dispatchEvent(new Event('cartUpdated'));
    };

    // const handleClearCart = () => {
    //     clearCart();
    //     setCart([]);
    // };

    // const handleCheckout = () => {
    //     // Call your function to convert the cart to Stripe line_items and proceed to checkout
    //     const stripeLineItems = convertCartToStripeLineItems();
    //     console.log(stripeLineItems);
    //     // Redirect to Stripe Checkout
    //     // navigate('/checkout');
    // };
    const calculateSubtotal = () => {
        return cart.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2);
    };

    return (
            <Container>
                <Typography variant="h4" gutterBottom>Shopping Cart</Typography>
                <List>
                    {cart.map((item, index) => (
                        <div key={index}>
                            <ListItem>
                                <Grid container alignItems="center">
                                    <Grid item xs={2}>
                                        <Avatar
                                            // variant="square"
                                            src={item.image}
                                            alt={item.name}
                                            // sx={{ width: 56, height: 56 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={
                                                <>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.size_name}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">${item.price}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {item.quantity_adjustable && (
                                            <Box display="flex" alignItems="center">
                                                <IconButton onClick={() => handleQuantityChange(index, -1)}>
                                                    <RemoveIcon />
                                                </IconButton>
                                                <Typography>{item.quantity}</Typography>
                                                <IconButton onClick={() => handleQuantityChange(index, 1)}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                        {!item.quantity_adjustable && (
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                </List>
                {cart.length === 0 && (
                    <Typography variant="body1">Your cart is empty.</Typography>
                )}
                <Box mt={2}>
                    <Typography variant="h6">Subtotal: ${calculateSubtotal()} + tax</Typography>
                    <Typography variant="h6">Shipping: Free</Typography>
                </Box>
            <Box mt={2}>
                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClearCart}
                    disabled={cart.length === 0}
                >
                    Clear Cart
                </Button> */}
                <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleCheckout}
                    href="/payment"
                    disabled={cart.length === 0}
                    sx={{ ml: 2 }}
                >
                    Proceed to Payment
                </Button>
            </Box>
            <CheckoutBar/>
        </Container>
    );
};

export default Checkout;
