import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';

ReactGA.initialize('G-NET6DHQSGW'); // Replace with your Google Analytics tracking ID

// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
// import BugsnagPerformance from '@bugsnag/browser-performance';

// Bugsnag.start({
//   apiKey: '4cb8f2eade0a06e15a298ae32d1c75e3',
//   plugins: [new BugsnagPluginReact()]
// })
// BugsnagPerformance.start({ apiKey: '4cb8f2eade0a06e15a298ae32d1c75e3' })


// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://7000e8606b661ecd0d6c4b7d3fcc072f@o4507173704237056.ingest.us.sentry.io/4507173706203136",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
