import React, { useEffect } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import RedirectButton from '../RedirectButton';
import {clearCart} from './storeFunctions';

function CheckoutSuccess() {
    
    // Trigger redirect immediately, or based on some condition or event
    useEffect(() => {
        clearCart();
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
            <Card sx={{ maxWidth: '90vw', width: '100%' }}>
            <CardContent>
                <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="h6" component="div" gutterBottom>
                    Check out complete
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="body1" component="div" gutterBottom>
                    Thanks for your purchase! You will receive an email shortly with tracking details or download links for digital copies.
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                <RedirectButton to={"/dashboard"} color="primary" variant="contained">
                    Dashboard
                </RedirectButton>
                </Box>
                </CardContent>
            </Card>
        </Box>
    ); // Render nothing or some loading indicator
}

export default CheckoutSuccess;