// import { useState } from 'react';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import { Link, Typography } from '@mui/material';
import {isLoggedIn} from '../utilities';

export default function NavMenu({ }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const destination = isLoggedIn() ? "/dashboard" : "/";


  const handleSignOut = () => {
    localStorage.removeItem('token');
  };

  return (
    <>
       { isLoggedIn() ?
          <Link href={"/"} underline="none" onClick={handleSignOut}>
              <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                  Sign Out
              </Typography>
          </Link>
        :
          <Link href={"/sign_up/"} underline="none" >
              <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                  Sign in
              </Typography>
          </Link>
        }
    </>
  );
}
