import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { authGet } from '../utilities';


const PendingReviews = () => {
  const [pendingReviews, setPendingReviews] = useState([]);

  useEffect(() => {
    const fetchPendingReviews = async () => {
      try {
        const data = await authGet('/api/reviews/');
        setPendingReviews(data);
      } catch (error) {
        console.error('Error fetching pending reviews:', error);
      }
    };

    fetchPendingReviews();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="pending reviews table">
        <TableHead>
          <TableRow>
            <TableCell>Pet Name</TableCell>
            <TableCell>Art Concept Name</TableCell>
            <TableCell>User Name</TableCell>
            <TableCell>Time Since Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingReviews.map((review) => (
            <TableRow key={review.id}>
              <TableCell>{review.pet_name}</TableCell>
              <TableCell>{review.art_concept_name}</TableCell>
              <TableCell>{review.user_name}</TableCell>
              <TableCell>{moment(review.created_at).fromNow()}</TableCell>
              <TableCell>
                <Link to={`/reviews/${review.id}`}>
                  View Details
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingReviews;
