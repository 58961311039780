import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import RedirectButton from '../RedirectButton';

function CheckoutCancel() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
            <Card sx={{ maxWidth: '90vw', width: '100%' }}>
            <CardContent>
                <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="h6" component="div" gutterBottom>
                    Check out cancelled
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="body1" component="div" gutterBottom>
                    Need to change something?
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                <RedirectButton to={"/store"} color="primary" variant="contained">
                    Store
                </RedirectButton>
                </Box>
                </CardContent>
            </Card>
        </Box>
    ); // Render nothing or some loading indicator
}

export default CheckoutCancel;