import React from 'react';
import { Box, Button, Card, CardContent, LinearProgress, TextField, Typography } from '@mui/material';
import PhotoDropzone from './PhotoDropzone';
import {MINIMUM_PHOTOS, MAXIMUM_PHOTOS} from '../settings.js';


const PhotoUploadCard = ({
  error,
  currentIndex,
  userImages,
  showDropzone,
  onFilesAccepted,
  isMobile,
  handleCaptionChange,
  handleNext,
  handlePrevious,
  handleReview,
  navigateBack,
  progress,
  progressColor
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="82vh">
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Card sx={{ maxWidth: '90vw', width: '100%' }}>
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            Upload Photos ({currentIndex + 1} / 15)
          </Typography>
          {showDropzone ? (
            <PhotoDropzone onFilesAccepted={onFilesAccepted} isMobile={isMobile} currentIndex={currentIndex} />
          ) : (
            userImages.length > 0 && (
              <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                <img src={userImages[currentIndex].preview} alt={`Preview ${currentIndex + 1}`} style={{ width: '100%', maxHeight: '40vh', objectFit: 'contain' }} />
                <TextField
                  placeholder="Write a caption that describes the scene in detail."
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={userImages[currentIndex].caption || ''}
                  onChange={handleCaptionChange}
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              </Box>
            )
          )}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" onClick={handlePrevious} disabled={currentIndex === 0}>
              Previous
            </Button>
            <Button variant="contained" onClick={handleNext} disabled={!userImages[currentIndex]?.caption || currentIndex >= MAXIMUM_PHOTOS - 1}>
              Next
            </Button>
          </Box>
        </CardContent>
        <Box px={2} pb={2}>
          <LinearProgress variant="determinate" value={progress} color={progressColor} />
          <Typography variant="body2" align="center" mt={1}>
            {userImages.length} / 15 images selected
          </Typography>
        </Box>
        <CardContent>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" onClick={navigateBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReview}
              disabled={userImages.length === 0 || currentIndex < userImages.length - 1 || userImages.length < MINIMUM_PHOTOS}
            >
              Review
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PhotoUploadCard;
