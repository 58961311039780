import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

const PhotoIntro = ({ handleNext }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="83vh">
        <Card sx={{ maxWidth: '90vw', width: '100%' }}>
          <CardContent>
            <Typography variant="h6" component="div" alignItems="center" gutterBottom>
              Let's get started!
            </Typography>
            <Typography component="div" alignItems="center" gutterBottom>
              In the next few steps, we're going to ask you to upload about 10-15 photos of your pet, 
              and to write captions describing the photos. <br></br><br></br>
              This will enable our AI to learn how to draw your pet. <br></br><br></br>
              It will only take a few minutes, and then we'll be able to use to use the AI to create stories and photos of your pet!
            </Typography>            
            <Box display="flex" justifyContent="center" mt={2}>
              <Button color="primary" variant="contained" onClick={handleNext}>
              Show me an example
              </Button>
            </Box>
          </CardContent>
          
        </Card>
      </Box>
  );
};

export default PhotoIntro;
