import React from 'react';
import { Box, Button, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';


const PhotoTips = ({ navigateBack, handleNext }) => {
    const doTips = [
        "Solo photos of your pet.",
        "Variety of angles and settings.",
        "Good lighting, clear photos."
      ];
    
      const dontTips = [
        "Multiple pets or people in photos.",
        "Blurry photos.",
        "Using filters."
      ];
    
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
          <Card sx={{ maxWidth: '90vw', width: '100%' }}>
            <CardContent>
              <Typography variant="h6" component="div" align="center" gutterBottom>
                Tips
              </Typography>
              <Typography component="div" align="center" gutterBottom>
                Here are some tips for picking photos to use:
              </Typography>
              
              <Box mt={2} display="flex" flexDirection="column" alignItems="left" justifyContent="left">
                <Typography variant="h6" component="div" align="left" gutterBottom>
                  Good photos
                </Typography>
                <List>
                  {doTips.map((tip, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </ListItemIcon>
                      <ListItemText primary={tip} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="h6" component="div" align="left" gutterBottom>
                  Bad photos
                </Typography>
                <List>
                  {dontTips.map((tip, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CancelIcon style={{ color: 'red' }} />
                      </ListItemIcon>
                      <ListItemText primary={tip} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="contained" onClick={navigateBack}>
                  Back
                </Button>
                <Button color="primary" variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
  );
};

export default PhotoTips;
